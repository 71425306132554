import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import PageHeader from '../../components/PageHeader';
import Slide from '../../components/Slide';
import SlideShow from '../../components/SlideShow';

const NUMBER_OF_SLIDES = 99;
const SLIDE_NAME = 'unge-med-stress';
const SLUG = '/haefter/diasshow-til-unge-med-stress';
const TITLE = 'Diasshow til unge med stress';
const SUBTITLE =
  'Hvad er stress, og hvilken rolle spiller bekymringerne i stress? Få svaret her – og løsningen på problemet';

export default function Page() {
  const slides = [...Array(NUMBER_OF_SLIDES).keys()].map((i) => (
    <Slide index={i + 1} total={NUMBER_OF_SLIDES} name={SLIDE_NAME} />
  ));

  return (
    <Layout>
      <Seo title={TITLE} description={SUBTITLE} url={SLUG} />

      <div className="columns is-centered">
        <div className="column text content">
          <PageHeader title={TITLE} subtitle={SUBTITLE} />

          <hr />

          <p>
            Stress handler langt hen ad vejen om bekymringer. Det er svært at
            forestille sig stress uden bekymringer. Det er bekymringerne, der
            får hjertet til at pumpe uhæmmet derudad, ligesom de trofast sørger
            for søvnløshedens hærgen i de sene nattetimer. Et tankeeksperiment
            viser, hvor stor betydning vores tanker – og dermed bekymringerne –
            har for at holde liv i stressen. Diasshowet klæder de unge mennesker
            på til de første indledende manøvrer i kampen mod stress, og lærer
            dem de vigtigste tegn på bæstet at kende. Bekymringernes rolle i
            stress, og hvordan vi reducerer dem til et niveau, hvor de ikke får
            hovedrollen i de unges liv og skæbner, bliver også berørt i denne
            tilgængelige rejse i stressens natur. God fornøjelse.
          </p>
        </div>
      </div>

      <SlideShow slides={slides} />
    </Layout>
  );
}
